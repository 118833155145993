import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook5/image/imgPortadaEbook5.png"
import img1 from "@components/pageEbook/ebooks/ebook5/image/imgCard1.png"
import img2 from "@components/pageEbook/ebooks/ebook5/image/imgCard2.png"
import img3 from "@components/pageEbook/ebooks/ebook5/image/imgCard3.png"
import slider1 from "@components/pageEbook/ebooks/ebook5/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook5/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook5/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook5/image/imgSilder4.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      20 KPIs de Call Center que debes de seguir
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
      Los indicadores o KPIs son como un termómetro que mide si las operaciones
      están en buen estado de salud o es que necesitan algún tipo de mejora.
      <br /> <br />
      Dada su importancia, en este nuevo e-book se recopila los principales KPIs
      que servirán para cada uno de los servicios que ofrece un call center.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
    El e-book quiere dar un plano general sobre la gestión de los call center
    con indicadores o KPIs que pueden ser utilizados en cualquier gestión del
    negocio.
    <br /> <br />
    Entre los KPIs encontrarás: Horarios pico, tiempo perdido por problemas
    técnicos, tiempo de espera promedio, Índice de ausencia y nivel de
    satisfacción de los agentes.
  </p>
)
const textTwo = (
  <p>
    Las campañas outbound son muy fuertes para los agentes, puesto que tienen
    que cumplir con objetivos fijos durante el mes. Por eso, se recopiló
    indicadores clave de rendimiento.
    <br /> <br />
    Dentro de ellos están: Plazo promedio de cobranzas, porcentaje de deuda
    vencida, Tiempo promedio de conversación, tasa de conversión y tasa de
    abandono de llamada.
  </p>
)
const textThree = (
  <p>
    Para este tipo de servicios se requieren KPIs como: Average handling time,
    tiempo de primera respuesta, tasa de resolución de llamadas, customer effort
    score y volumen de casos.
  </p>
)

const data = {
  start: {
    support: "Ebook | Call Center",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-kpi",
    link: linkForm,
    href: "/ebook-5.pdf",
    brochure: "ebook-20-KPIs-de-Call-Center",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "KPIs para la gestión de Call Center",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "KPIs de cobranza y ventas para Call center",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "KPIs de atención al cliente para Call Center",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "El significado de un KPI y su importancia en un Contact Center.",
      },
      {
        key: 2,
        text: "Reconocer los principales indicadores de gestión de un Contact Center.",
      },
      {
        key: 3,
        text: "Identificar los KPIs de una campaña de cobranzas y televentas.",
      },
      {
        key: 4,
        text: "Distinguir las métricas más importantes de una campaña de atención al cliente.",
      },
      {
        key: 5,
        text: "Principales KPIs para cada uno de los servicios que ofrece un Contact Center.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
